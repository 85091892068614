import React from 'react';
import ProductList from '../components/ProductList';

const Home: React.FC = () => {
    return (
        <div className="bg-gray-100" style={{
            paddingBottom: 120
        }}>
            <div className="px-0">
                <ProductList />
            </div>
        </div>
    );
};

export default Home;