import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ServicePopupContextProps {
    serviceId: number | null;
    detailServiceId: number | null;
    openPopup: (id: number) => void;
    closePopup: () => void;
    openServiceDetails: (id: number) => void;
}

const ServicePopupContext = createContext<ServicePopupContextProps | undefined>(undefined);

export const ServicePopupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [serviceId, setServiceId] = useState<number | null>(null);
    const [detailServiceId, setDetailServiceId] = useState<number | null>(null);

    const openPopup = (id: number) => setServiceId(id);
    const openServiceDetails = (id: number) => setDetailServiceId(id);
    const closePopup = () => {
        setServiceId(null)
        setDetailServiceId(null)
    };

    return (
        <ServicePopupContext.Provider value={{ serviceId, detailServiceId, openPopup, closePopup, openServiceDetails }}>
            {children}
        </ServicePopupContext.Provider>
    );
};

export const useServicePopup = () => {
    const context = useContext(ServicePopupContext);
    if (!context) {
        throw new Error('useServicePopup must be used within a ServicePopupProvider');
    }
    return context;
};