import React from 'react';
import { ServiceType } from '../types/types';
import AddToCartServiceButton from './AddToCartServiceButton';
import { useServicePopup } from '../contexts/ServicePopupContext';

interface ServiceCardProps {
    service: ServiceType;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
    const { openPopup } = useServicePopup();

    const fallbackImage = 'https://staging.lastsis.com/media/default-images/service-default.jpeg';

    const totalPrice = parseFloat(service.price) + parseFloat(service.total_vat);

    return (
        <div className="w-1/2">
            <div className="p-1">
                <div className="w-full bg-white">
                    <img
                        className="w-100"
                        alt={service.name}
                        style={{ width: '100%' }}
                        src={`https://lastsis.s3.amazonaws.com/lastsis/uploads/icons/${service.code}.jpg`}
                        onError={(e) => {
                            (e.target as HTMLImageElement).src = fallbackImage;
                        }}
                    />
                </div>
                <div className="col-12 cat-pro-info bg-white p-2">
                    <a href="#" className="flex w-100" title={service.name}>
                        <h3
                            className="font-bold text-[13px] text-[#333] leading-[16px] overflow-hidden text-ellipsis"
                            style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                            title={service.name}
                        >
                            {service.name}
                        </h3>
                    </a>
                    <div className="cat-pro-brand-info my-1 flex flex-col w-100">
                        <div className="flex flex-row justify-between items-center center my-3">
                                <strong className="text-[16px] text-[#0a8c06]">
                                    {totalPrice.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} TL
                                </strong>
                                {service.sellers && service.sellers_count ? 
                                <span className="text-[12px] text-[#333] font-bold cursor-pointer" onClick={() => openPopup(service.id)}>Satıcılar ({service.sellers_count ?? 0})</span> : null}
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        <AddToCartServiceButton serviceId={service.id} firmId={service.firm_id} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;