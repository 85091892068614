import React, {useEffect, useState} from 'react';
// @ts-ignore
import MaskedInput from 'react-input-mask';
import {useCart} from "../contexts/CartContext";
import {useForm} from "../contexts/FormContext";
import CardMockup from "./CardMockup";
import axiosInstance from "../axiosInstance";
import {BasketProductType, BasketServiceType} from "../types/types";
import ClipLoader from 'react-spinners/ClipLoader';
// @ts-ignore
import Modal from 'react-modal';
import {useNavigate} from "react-router-dom";
import {useAuthMiddleware} from "../contexts/AuthMiddlewareContext";

const customInputStyle =
    "bg-[#999999] bg-opacity-10 rounded-md focus:outline-none leading-[44px] px-[15px] border-0 placeholder-[#999999] text-[14px]";

interface PaymentFormProps {
    onSave: (paymentData: any) => void;
}

const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 })
        .format(amount)
        .replace('₺', '') // TRY sembolünü kaldırmak isterseniz

const PaymentForm: React.FC<PaymentFormProps> = ({ onSave }) => {
    const { cartItems, getItemQuantity, clearCart } = useCart();
    const { payment, updatePayment } = useForm();
    const navigate = useNavigate();
    const { userData } = useAuthMiddleware();

    const [products, setProducts] = useState<BasketProductType[]>([]);
    const [services, setServices] = useState<BasketServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [showCartPopup, setShowCartPopup] = useState(false);
    const togglePopup = () => setShowCartPopup(!showCartPopup);

    const [isProcessing, setIsProcessing] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        updatePayment({ [name]: value });
    };

    const validatePayment = () => {
        const validationErrors: Record<string, string> = {};

        if (!payment.card_number || payment.card_number.replace(/\s+/g, '').length !== 16) {
            validationErrors.cardNumber = 'Geçerli bir kart numarası girin.';
        }

        if (!payment.card_date || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(payment.card_date)) {
            validationErrors.expiryDate = 'Geçerli bir son kullanım tarihi girin (AA/YY).';
        }

        if (!payment.card_cvv || payment.card_cvv.length !== 3) {
            validationErrors.cvv = 'CVV 3 haneli olmalıdır.';
        }

        return validationErrors;
    };

    useEffect(() => {
        const fetchBasketProducts = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const productIds = cartItems.filter(item => item.product_id).map((item) => item.product_id).join(',');
                if(productIds.length > 0) {
                    const response = await axiosInstance.get(`/ecommerce/basket`, {
                        params: { 'filter[id]': productIds, 'include': 'pool,firm' },
                    });
                    setProducts(response.data.data);
                }else{
                    setProducts([]);
                }
            } catch (error) {
                //setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketProducts();
    }, [cartItems]);

    useEffect(() => {
        const fetchBasketServices = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const serviceIds = cartItems.filter(item => item.service_id).map((item) => item.service_id).join(',');
                if(serviceIds.length > 0) {
                    const response = await axiosInstance.get(`/ecommerce/basket/services`, {
                        params: { 'filter[id]': serviceIds, 'include': 'category,firm' },
                    });
                    setServices(response.data.data);
                }else{
                    setServices([]);
                }
            } catch (error) {
                //setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketServices();
    }, [cartItems]);

    const totalProductAmount = products.reduce(
        (acc, product) => acc + (parseFloat(product.price) + parseFloat(product.total_vat)) * getItemQuantity({
            product_id: product.id
        }),
        0
    );

    const totalServiceAmount = services.reduce(
        (acc, service) => acc + (parseFloat(service.price) + parseFloat(service.total_vat)) * getItemQuantity({
            service_id: service.id
        }),
        0
    );

    const totalAmount = totalProductAmount + totalServiceAmount;

    const handlePaymentSubmit = () => {
        const validationErrors = validatePayment();
        if (Object.keys(validationErrors).length === 0) {
            setIsProcessing(true);

            axiosInstance.post('/ecommerce/order', {
                ...payment,
                customer: {
                    reference: userData?.id,
                    type: 'private',
                    name: userData.firstname,
                    surname: userData.lastname,
                    email: userData.email,
                    phone: userData.telefon
                },
                vehicle: {
                    plate: userData.plaka,
                },
                cart: cartItems
            })
                .then((response) => {
                    if(!response.data.status){
                        if(response.data.order.form3d){
                            window.location.href = response.data.order.form3d;
                        } else {
                            // Form3d yoksa ve status 0 ise hata modalını göster
                            setErrorMessage(response.data.order.all.message || 'Ödeme işlemi başarısız oldu.');
                            setShowErrorModal(true);
                        }
                    } else {
                        setShowSuccessModal(true);
                        clearCart();
                    }
                })
                .catch((error) => {
                    setErrorMessage(error.response?.data?.order?.all?.message || 'Ödeme işlemi sırasında bir hata oluştu.');
                    setShowErrorModal(true);
                })
                .finally(() => {
                    setIsProcessing(false);
                });

            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            {isProcessing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-full">
                        <ClipLoader color="#3498db" size={50} />
                    </div>
                    <span className="text-white ml-3 font-medium">Ödeme işleniyor...</span>
                </div>
            )}

            <div className="space-y-4 mt-4 bg-white p-3 rounded-[6px] shadow-sm">
                <h5><strong>Ödeme</strong></h5>
                <CardMockup />

                <input
                    type="text"
                    name="card_name"
                    placeholder="Kart Sahibi"
                    value={payment.card_name}
                    className={`w-full ${customInputStyle}`}
                    onChange={handleInputChange}
                />
                {errors.card_name && <p className="text-red-500 text-sm">{errors.card_name}</p>}

                <MaskedInput
                    mask="9999 9999 9999 9999"
                    name="card_number"
                    placeholder="Kart Numarası"
                    value={payment.card_number}
                    className={`w-full ${customInputStyle}`}
                    onChange={handleInputChange}
                />
                {errors.cardNumber && <p className="text-red-500 text-sm">{errors.cardNumber}</p>}

                <MaskedInput
                    mask="99/99"
                    name="card_date"
                    placeholder="Son Kullanma Tarihi (AA/YY)"
                    value={payment.card_date}
                    className={`w-full ${customInputStyle}`}
                    onChange={handleInputChange}
                />
                {errors.expiryDate && <p className="text-red-500 text-sm">{errors.expiryDate}</p>}

                <input
                    type="text"
                    name="card_cvv"
                    placeholder="CVV"
                    value={payment.card_cvv}
                    className={`w-full ${customInputStyle}`}
                    onChange={handleInputChange}
                    maxLength={3}
                />
                {errors.cvv && <p className="text-red-500 text-sm">{errors.cvv}</p>}

                <div className="fixed bottom-[60px] left-0 w-full bg-white shadow-md p-4 flex items-center justify-between border-t">
                    <div className="flex flex-row items-center" onClick={togglePopup}>
                        <h2 className="font-bold text-[14px]">Toplam Tutar: {formatCurrency(totalAmount)} TL</h2>
                    </div>

                    <button
                        className={`px-2 py-2 bg-green-500 text-[12px] text-white rounded-md hover:bg-green-600 transition`}
                        onClick={handlePaymentSubmit}
                    >
                        Ödemeyi Tamamla
                    </button>
                </div>
            </div>

            {/* Başarılı Ödeme Modal */}
            <Modal
                isOpen={showSuccessModal}
                onRequestClose={() => navigate('/')}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        width: '80%',
                        maxWidth: '400px',
                        borderRadius: '10px',
                        textAlign: 'center',
                    },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
                ariaHideApp={false}
            >
                <div className="flex flex-col items-center">
                    <div className="w-16 h-16 rounded-full bg-green-500 flex items-center justify-center mb-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                    <h2 className="text-xl font-bold">Ödeme Başarılı!</h2>
                    <p>Şimdi siparişinizle siparişiniz için direkt adrese giderek teslim alabilirsiniz.</p>
                    <button
                        onClick={() => navigate('/')}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    >
                        Tamam
                    </button>
                </div>
            </Modal>

            {/* Hata Modalı */}
            <Modal
                isOpen={showErrorModal}
                onRequestClose={() => setShowErrorModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        width: '80%',
                        maxWidth: '400px',
                        borderRadius: '10px',
                        textAlign: 'center',
                    },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
                ariaHideApp={false}
            >
                <div className="flex flex-col items-center">
                    <div className="w-16 h-16 rounded-full bg-red-500 flex items-center justify-center mb-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                    <h2 className="text-xl font-bold text-red-500">Ödeme Hatası</h2>
                    <p className="mt-2 text-gray-600">{errorMessage}</p>
                    <button
                        onClick={() => setShowErrorModal(false)}
                        className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    >
                        Tamam
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default PaymentForm;