// src/contexts/FormContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Customer {
    companyName?: string;
    taxNumber?: string;
    taxOffice?: string;
    name?: string;
    tcNumber?: string;
    address?: string;
    city?: string;
    district?: string;
    email?: string;
    phone?: string;
}

interface Vehicle {
    plate?: string;
    brand?: string;
    model?: string;
    type?: string;
}

interface Payment {
    card_name?: string;
    card_number?: string;
    card_date?: string;
    card_cvv?: string;
}

interface FormContextType {
    customer: Customer;
    vehicle: Vehicle;
    payment: Payment;
    updateCustomer: (data: Partial<Customer>) => void;
    updateVehicle: (data: Partial<Vehicle>) => void;
    updatePayment: (data: Partial<Payment>) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useForm = (): FormContextType => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useForm must be used within a FormProvider');
    }
    return context;
};

export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [customer, setCustomer] = useState<Customer>({});
    const [vehicle, setVehicle] = useState<Vehicle>({});
    const [payment, setPayment] = useState<Payment>({});

    const updateCustomer = (data: Partial<Customer>) => {
        setCustomer((prev) => ({ ...prev, ...data }));
    };

    const updateVehicle = (data: Partial<Vehicle>) => {
        setVehicle((prev) => ({ ...prev, ...data }));
    };

    const updatePayment = (data: Partial<Payment>) => {
        setPayment((prev) => ({ ...prev, ...data }));
    };

    return (
        <FormContext.Provider value={{ customer, vehicle, payment, updateCustomer, updateVehicle, updatePayment }}>
            {children}
        </FormContext.Provider>
    );
};