import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { useFilter } from "../contexts/FilterContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Filter: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [filterOptions, setFilterOptions] = useState<any>({});
    const { applyFilters } = useFilter();

    const [search, setSearch] = useState('');
    const [category, setCategory] = useState('');
    const [brand, setBrand] = useState('');
    const [season, setSeason] = useState('');
    const [width, setWidth] = useState('');
    const [sidewall, setSidewall] = useState('');
    const [rim, setRim] = useState('');

    const handleSubmit = () => {
        const newFilters: Record<string, string> = {};

        if (search) newFilters['filter[search]'] = search;
        if (category) newFilters['filter[category]'] = category;
        if (brand) newFilters['filter[brand]'] = brand;
        if (season) newFilters['filter[season]'] = season;
        if (width || sidewall || rim) {
            newFilters['filter[size]'] = `${width}/${sidewall}R${rim}`;
        }

        applyFilters(newFilters);
        setIsMenuOpen(false); // Menü kapanır
    };

    useEffect(() => {
        axiosInstance.get('/ecommerce/common')
            .then((response) => setFilterOptions(response.data))
            .catch((error) => console.error('API Error:', error));
    }, []);

    const customInputStyle =
        "bg-[#999999] bg-opacity-10 rounded-[6px] focus:outline-none leading-[44px] px-[15px] border-0 p-2 placeholder-[#999999] text-[12px]";

    return (
        <div className="flex flex-row w-100 justify-between py-2" style={{ width: '100%'}}>
            {/* Filtrele Butonu */}
            <button
                onClick={() => setIsMenuOpen(true)}
                className="px-4 py-2 rounded-[6px] bg-gray-200 text-black border border-gray-300 flex flex-row items-center"
            >
                <img src="/filtrele.png" className={`w-[20px] mr-2`} alt=""/>Filtrele
            </button>
            <div className={`flex flex-row`}>
                <input
                    type="text"
                    placeholder="Arama kelimesini giriniz..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={`w-full border border-gray-300 ${customInputStyle}`} style={{
                        padding: 10,
                        lineHeight: '30px',
                        width: 225,
                        border: '1px solid rgb(209, 213, 219)',
                        backgroundColor: 'rgb(229, 231, 235)',
                        borderTopRightRadius:0,
                        borderBottomRightRadius:0,
                }}
                />
                <button
                    onClick={handleSubmit}
                    className="w-full bg-gray-700 text-white p-1 text-[14px] rounded-md hover:bg-blue-600 transition"
                    style={{
                        borderTopLeftRadius:0,
                        borderBottomLeftRadius:0,
                        width: 55
                    }}
                >
                    Ara
                </button>
            </div>

            {isMenuOpen && (
                <>
                    {/* Sağdaki opak alan, menüyü kapatmak için */}
                    <div
                        className="fixed inset-0 bg-black opacity-70 z-40"
                        onClick={() => setIsMenuOpen(false)}
                    ></div>

                    {/* Filtre Menüsü (Sola Yaslanmış) */}
                    <div className="fixed inset-y-0 left-0 w-4/5 bg-white p-6 z-50 overflow-auto shadow-lg">
                        <button
                            className="absolute top-4 right-4 h-8 w-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 transition shadow-md"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} size="sm" className="text-gray-600" />
                        </button>

                        <input
                            type="text"
                            placeholder="Arama kelimesini giriniz..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className={`w-full mb-4 mt-10 ${customInputStyle}`}
                        />

                        <h5 className={`text-[14px] font-bold`}>Kategori</h5>
                        <select
                            className={`w-full mb-4 ${customInputStyle} appearance-none`}
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Kategori Seçin</option>
                            {filterOptions.categories.map((category: string) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>

                        <h5 className={`text-[14px] font-bold`}>Marka</h5>
                        <select
                            className={`w-full mb-4 ${customInputStyle} appearance-none`}
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                        >
                            <option value="">Marka Seçin</option>
                            {filterOptions.brands.map((brand: string) => (
                                <option key={brand} value={brand}>
                                    {brand}
                                </option>
                            ))}
                        </select>

                        <h5 className={`text-[14px] font-bold`}>Mevsim</h5>
                        <select
                            className={`w-full mb-4 ${customInputStyle} appearance-none`}
                            value={season}
                            onChange={(e) => setSeason(e.target.value)}
                        >
                            <option value="">Mevsim Seçin</option>
                            {filterOptions.seasons.map((season: string) => (
                                <option key={season} value={season}>
                                    {season}
                                </option>
                            ))}
                        </select>

                        <div className="flex space-x-4">
                            <div className="w-1/3">
                                <h5 className={`text-[14px] font-bold`}>Taban</h5>
                                <select
                                    className={`w-full mb-4 ${customInputStyle} appearance-none`}
                                    value={width}
                                    onChange={(e) => setWidth(e.target.value)}
                                >
                                    <option value="">Taban Seçin</option>
                                    {filterOptions.widths.map((width: number) => (
                                        <option key={width} value={width}>
                                            {width}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-1/3">
                                <h5 className={`text-[14px] font-bold`}>Yanak</h5>
                                <select
                                    className={`w-full mb-4 ${customInputStyle} appearance-none`}
                                    value={sidewall}
                                    onChange={(e) => setSidewall(e.target.value)}
                                >
                                    <option value="">Yanak Seçin</option>
                                    {filterOptions.sidewalls.map((sidewall: number) => (
                                        <option key={sidewall} value={sidewall}>
                                            {sidewall}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-1/3">
                                <h5 className={`text-[14px] font-bold`}>Jant</h5>
                                <select
                                    className={`w-full mb-4 ${customInputStyle} appearance-none`}
                                    value={rim}
                                    onChange={(e) => setRim(e.target.value)}
                                >
                                    <option value="">Jant Seçin</option>
                                    {filterOptions.rims.map((rim: number) => (
                                        <option key={rim} value={rim}>
                                            {rim}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition"
                        >
                            Arama Yap
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Filter;