import React, { useEffect, useState } from 'react';
// @ts-ignore
import Modal from 'react-modal';
import axiosInstance from '../axiosInstance';
import ClipLoader from 'react-spinners/ClipLoader';
import { useProductPopup } from '../contexts/ProductPopupContext';
import { ProductType } from '../types/types';

const customStyles: Modal.Styles = {
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1000 },
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        padding: '10px',
        width: '90%',
        maxHeight: '80vh',
        overflowY: 'auto',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    },
};

const ProductDetail: React.FC = () => {
    const { detailProductId, closePopup } = useProductPopup();
    const [product, setProduct] = useState<ProductType | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fallbackImage = 'https://staging.lastsis.com/media/default-images/tire-default.jpeg';

    useEffect(() => {
        if (detailProductId) {
            setLoading(true);
            axiosInstance
                .get(`/ecommerce/products/${detailProductId}`)
                .then((response) => setProduct(response.data.data))
                .catch(() => setError('Ürün bilgileri alınırken hata oluştu.'))
                .finally(() => setLoading(false));
        }
    }, [detailProductId]);

    if (!detailProductId) return null;

    return (
        <Modal isOpen={!!detailProductId} onRequestClose={closePopup} style={customStyles} ariaHideApp={false}>
            {loading ? (
                <div className="flex justify-center items-center">
                    <ClipLoader color="#3498db" size={50} />
                </div>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : (
                <div className="space-y-6">
                    {/* Ürün Başlığı ve Görseli */}
                    <div className="flex flex-col items-center space-x-6">
                        <img
                            src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product?.real_image}`}
                            alt={product?.name}
                            className="w-[200px] object-cover"
                            onError={(e) => {
                                (e.target as HTMLImageElement).src = fallbackImage;
                            }}
                        />
                        <div>
                            <h2 className="text-2xl font-bold">{product?.name}</h2>
                            <p className="text-md text-gray-600">
                                {product?.category} - {product?.season}
                            </p>
                        </div>
                    </div>

                    {/* Ürün Detay Tablosu */}
                    <div className="overflow-auto">
                        <table className="w-full border-collapse border border-gray-300">
                            <tbody>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Marka</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.brand}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Model</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.pattern}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Ebat</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.size}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Yük Endeksi</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.yuk}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Hız Endeksi</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.hiz}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Stok Kodu</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.stock_code}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Ses Seviyesi</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.noise_level}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Yol Tutuşu</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.brake_performance}</td>
                            </tr>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">Verimlilik</th>
                                <td className="border border-gray-300 px-4 py-2">{product?.energy_class}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Kapat Butonu */}
                    <button
                        onClick={closePopup}
                        className="mt-4 w-full px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    >
                        Kapat
                    </button>
                </div>
            )}
        </Modal>
    );
};

export default ProductDetail;