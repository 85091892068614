import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import ClipLoader from 'react-spinners/ClipLoader';

interface PaymentResponse {
    status: number;
    error?: string;
    message?: {
        transactionId: string;
        code: string;
        status: string;
        operation: string;
        type: string;
        message: string;
    };
}

const Confirmation: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [response, setResponse] = useState<PaymentResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderConfirmation = async () => {
            try {
                const result = await axiosInstance.get('/ecommerce/payment/callback', {
                    params: {
                        order: orderId
                    }
                });
                setResponse(result.data);
            } catch (err) {
                setError('Sipariş bilgileri alınamadı');
            } finally {
                setLoading(false);
            }
        };

        if (orderId) {
            fetchOrderConfirmation();
        }
    }, [orderId]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50} />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-red-500 text-center">
                    <h2 className="text-xl font-bold mb-2">Hata</h2>
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    if (!response) return null;

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
            <div className="bg-white rounded-lg shadow-sm p-8 max-w-md w-full mx-4">
                {response.status === 1 ? (
                    // Başarılı ödeme durumu
                    <div className="text-center">
                        <div className="w-16 h-16 bg-green-500 rounded-full mx-auto flex items-center justify-center mb-4">
                            <svg 
                                className="w-8 h-8 text-white" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M5 13l4 4L19 7" 
                                />
                            </svg>
                        </div>
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">
                            Ödeme Başarılı!
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Siparişiniz başarıyla tamamlandı.
                        </p>
                        <button
                            onClick={() => navigate('/')}
                            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors"
                        >
                            Anasayfaya Dön
                        </button>
                    </div>
                ) : (
                    // Başarısız ödeme durumu
                    <div className="text-center">
                        <div className="w-16 h-16 bg-red-500 rounded-full mx-auto flex items-center justify-center mb-4">
                            <svg 
                                className="w-8 h-8 text-white" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M6 18L18 6M6 6l12 12" 
                                />
                            </svg>
                        </div>
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">
                            Ödeme Başarısız
                        </h2>
                        {response.message && (
                            <div className="mb-6">
                                <p className="text-gray-600">
                                    Hata Kodu: {response.message.code}
                                </p>
                                <p className="text-gray-600">
                                    {response.message.message}
                                </p>
                            </div>
                        )}
                        <button
                            onClick={() => navigate('/checkout')}
                            className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition-colors"
                        >
                            Ödemeye Geri Dön
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Confirmation; 