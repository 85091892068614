import React from 'react';
import ServiceList from '../components/ServiceList';

const Services: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100">
            <div className="px-1">
                <ServiceList />
            </div>
        </div>
    );
};

export default Services;