// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import { useCart } from '../contexts/CartContext';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Header: React.FC = () => {
    const { cartItems } = useCart();
    const [isAnimating, setIsAnimating] = useState(false);

    // Sepetteki toplam ürün sayısını hesapla
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

    // Animasyonu tetikleme
    useEffect(() => {
        if (totalItems > 0) {
            setIsAnimating(true);
            setTimeout(() => setIsAnimating(false), 500); // Animasyon bitişi
        }
    }, [totalItems]);

    const cartIconVariants = {
        initial: { scale: 1 },
        animate: { scale: 1.1 },
        transition: { type: 'spring', stiffness: 300, damping: 15 },
    };

    return (
        <header className="fixed bottom-0 left-0 w-full flex justify-center items-center bg-white p-1 shadow-md shadow-gray-400" style={{
            borderTop: '1px solid rgb(59, 130, 246)'

        }}>
            <Link to="/" className="relative bg-white text-gray-900 text-[10px] font-bold p-1 rounded-[6px] flex-col flex items-center w-[85px] mr-2">
                <motion.div
                    className=""
                    variants={cartIconVariants}
                    initial="initial"
                    animate={isAnimating ? 'animate' : 'initial'}
                >
                    <img src={`/urunler.png`} alt='Ürünler' className={`w-[30px]`} />
                </motion.div>
                Ürünler
            </Link>
            <Link to="/services" className="relative bg-white text-gray-900 text-[10px] font-bold p-1 rounded-[6px] flex-col flex items-center w-[85px] mr-2">
                <motion.div
                    className=""
                    variants={cartIconVariants}
                    initial="initial"
                    animate={isAnimating ? 'animate' : 'initial'}
                >
                    <img src={`/hizmetler.png`} alt='Hizmetler' className={`w-[30px]`} />
                </motion.div>
                Hizmetler
            </Link>
            <Link to="/orders" className="relative bg-white text-gray-900 text-[10px] font-bold p-1 rounded-[6px] flex-col flex items-center w-[85px] mr-2">
                <motion.div
                    className=""
                    variants={cartIconVariants}
                    initial="initial"
                    animate={isAnimating ? 'animate' : 'initial'}
                >
                    <img src={`/siparislerim.png`} alt='Siparişlerim' className={`w-[30px]`} />
                </motion.div>
                Siparişlerim
            </Link>
            <Link to="/cart" className="relative text-gray-900 bg-white text-[10px] font-bold p-1 rounded-[6px] flex-col flex items-center w-[85px]">
                <motion.div
                    className=""
                    variants={cartIconVariants}
                    initial="initial"
                    animate={isAnimating ? 'animate' : 'initial'}
                >
                    <img src={`/sepet.png`} alt='Sepetim' className={`w-[30px]`} />
                </motion.div>
                {totalItems > 0 && (
                    <motion.span
                        className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold px-1 py-0 rounded-full"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        {totalItems}
                    </motion.span>
                )}
                Sepetim
            </Link>
        </header>
    );
};

export default Header;