import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FilterContextProps {
    filters: Record<string, string>;
    applyFilters: (newFilters: Record<string, string>) => void;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [filters, setFilters] = useState<Record<string, string>>({}); // Filtreleri burada tutuyoruz

    const applyFilters = (newFilters: Record<string, string>) => {
        setFilters(newFilters); // Filtreleri toplu olarak güncelliyoruz
    };

    return (
        <FilterContext.Provider value={{ filters, applyFilters }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilter = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilter must be used within a FilterProvider');
    }
    return context;
};