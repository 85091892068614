import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

interface UserData {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    telefon: string;
    plaka: string;
}

interface AuthMiddlewareContextType {
    userData: UserData | null;
    isLoading: boolean;
}

const AuthMiddlewareContext = createContext<AuthMiddlewareContextType>({
    userData: null,
    isLoading: true,
});

export const useAuthMiddleware = () => useContext(AuthMiddlewareContext);

export const AuthMiddlewareProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userData, setUserData] = useState<UserData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userIdFromUrl = queryParams.get('userid');

        if (userIdFromUrl) {
            // Yeni kullanıcı ID'si varsa LocalStorage'a kaydet
            localStorage.setItem('userId', userIdFromUrl);
            fetchUserData(userIdFromUrl);
        } else {
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                // Kaydedilen ID'yi kullan
                fetchUserData(storedUserId);
            } else {
                // ID yoksa hata sayfasına yönlendir
                navigate('/error');
            }
        }
    }, [location.search]);

    const fetchUserData = async (userId: string) => {
        try {
            const response = await axiosInstance.get(`/ecommerce/user/${userId}`);
            const user = response.data.data;
            setUserData(user);
        } catch (error) {
            showErrorPopup('Kullanıcı doğrulanamadı!');
        } finally {
            setIsLoading(false);
        }
    };

    const showErrorPopup = (message: string) => {
        Swal.fire({
            icon: 'error',
            title: 'Hata!',
            text: message,
            confirmButtonText: 'Tamam',
        }).then(() => {
            navigate('/error');
        });
    };

    return (
        <AuthMiddlewareContext.Provider value={{ userData, isLoading }}>
            {!isLoading && children}
        </AuthMiddlewareContext.Provider>
    );
};