import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ProductPopupContextProps {
    productId: number | null;
    detailProductId: number | null;
    openPopup: (id: number) => void;
    closePopup: () => void;
    openProductDetails: (id: number) => void; // Yeni ekleme
}

const ProductPopupContext = createContext<ProductPopupContextProps | undefined>(undefined);

export const ProductPopupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [productId, setProductId] = useState<number | null>(null);
    const [detailProductId, setDetailProductId] = useState<number | null>(null);

    const openPopup = (id: number) => setProductId(id);
    const openProductDetails = (id: number) => setDetailProductId(id); // Detay için açma
    const closePopup = () => {
        setProductId(null)
        setDetailProductId(null)
    };

    return (
        <ProductPopupContext.Provider value={{ productId, detailProductId, openPopup, closePopup, openProductDetails }}>
            {children}
        </ProductPopupContext.Provider>
    );
};

export const useProductPopup = () => {
    const context = useContext(ProductPopupContext);
    if (!context) {
        throw new Error('useProductPopup must be used within a ProductPopupProvider');
    }
    return context;
};