// src/components/CardMockup.tsx
import React from 'react';
import { useForm } from '../contexts/FormContext';
import './CardMockup.css';

const CardMockup: React.FC = () => {
    const { payment } = useForm();

    return (
        <div className="card-mockup">
            <div className="card-header">
                <span className="bank-name">KREDİ KARTI</span>
            </div>
            <div className="card-number">
                {payment.card_number || '**** **** **** ****'}
            </div>
            <div className="card-info">
                <div className="card-holder">
                    <span>Kart Sahibi</span>
                    <span>{payment.card_name || 'Ad Soyad'}</span>
                </div>
                <div className="card-expiry">
                    <span>Son Kullanma</span>
                    <span>{payment.card_date || 'MM/YY'}</span>
                </div>
            </div>
        </div>
    );
};

export default CardMockup;