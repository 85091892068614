// src/pages/CartPage.tsx
import React, { useEffect, useState } from 'react';
import { useCart } from '../contexts/CartContext';
import axiosInstance from '../axiosInstance';
import ClipLoader from 'react-spinners/ClipLoader';
import { BasketProductType, BasketServiceType, ServiceType } from "../types/types";
import AddToCartButton from "../components/AddToCartButton";
import {useNavigate} from "react-router-dom";
import AddToCartServiceButton from '../components/AddToCartServiceButton';
import ServiceCard from '../components/ServiceCard';

// Sayı formatlama fonksiyonu
const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 2 })
        .format(amount)
        .replace('₺', '') // TRY sembolünü kaldırmak isterseniz

const CartPage: React.FC = () => {
    const { cartItems, getItemQuantity } = useCart();
    const [products, setProducts] = useState<BasketProductType[]>([]);
    const [services, setServices] = useState<BasketServiceType[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [firmServices, setFirmServices] = useState<ServiceType[]>([]);
    const [firmId, setFirmId] = useState<number | null>(null);
    const [firmName, setFirmName] = useState<string>('');

    const fallBackServiceImage = 'https://staging.lastsis.com/media/default-images/service-default.jpeg';

    useEffect(() => {
        const fetchBasketProducts = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const productIds = cartItems.filter(item => item.product_id).map((item) => item.product_id).join(',');
                if(productIds.length > 0) {
                    const response = await axiosInstance.get(`/ecommerce/basket`, {
                        params: { 'filter[id]': productIds, 'include': 'pool,firm' },
                    });
                    setProducts(response.data.data);
                }else{
                    setProducts([]);
                }
            } catch (error) {
                setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketProducts();
    }, [cartItems]);

    useEffect(() => {
        const fetchBasketServices = async () => {
            try {
                if (cartItems.length === 0) {
                    setProducts([]);
                    setLoading(false);
                    return;
                }

                const serviceIds = cartItems.filter(item => item.service_id).map((item) => item.service_id).join(',');
                if(serviceIds.length > 0) {
                const response = await axiosInstance.get(`/ecommerce/basket/services`, {
                    params: { 'filter[id]': serviceIds, 'include': 'category,firm' },
                });
                    setServices(response.data.data);
                }else{
                    setServices([]);
                }
            } catch (error) {
                setError('Sepet ürünleri yüklenirken hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBasketServices();
    }, [cartItems]);

    // Firma ID'sini ve adını al
    useEffect(() => {
        if (products.length > 0 && services.length === 0) {
            const firstProduct = products[0];
            setFirmId(firstProduct.firm_id);
            setFirmName(firstProduct.firm.caption);
        }
    }, [products, services]);

    // Firma hizmetlerini getir
    useEffect(() => {
        const fetchFirmServices = async () => {
            if (!firmId) return;

            try {
                const response = await axiosInstance.get('/ecommerce/services', {
                    params: {
                        'filter[firm_id]': firmId,
                        'include': 'category'
                    }
                });
                setFirmServices(response.data.data);
            } catch (error) {
                console.error('Firma hizmetleri yüklenirken hata oluştu:', error);
            }
        };

        if (services.length === 0 && firmId) {
            fetchFirmServices();
        }
    }, [firmId, services.length]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50} />
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }


    if (products.length === 0 && services.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/4076/4076432.png"
                    alt="No Orders"
                    className="w-24 h-24 mb-4"
                />
                <p className="text-lg font-bold text-gray-700">Sepetiniz boş.</p>
                <button
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition mt-4"
                >
                    Ana Sayfaya Dön
                </button>
            </div>
        );
    }

    const totalProductAmount = products.reduce(
        (acc, product) => acc + (parseFloat(product.price) + parseFloat(product.total_vat)) * getItemQuantity({
            product_id: product.id
        }),
        0
    );

    const totalServiceAmount = services.reduce(
        (acc, service) => acc + (parseFloat(service.price) + parseFloat(service.total_vat)) * getItemQuantity({
            service_id: service.id
        }),
        0
    );

    const totalAmount = totalProductAmount + totalServiceAmount;

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            {/* Başlık ve Sepet Listesi */}
            <div className="flex-1 p-4" style={{
                paddingBottom: 220
            }}>
                <h1 className="text-2xl font-bold mb-6">Sepetim</h1>
                <div className="space-y-4">
                    {products.map((product) => (
                        <div
                            key={product.id}
                            className="flex items-start justify-between border-b pb-4 bg-white rounded-[6px] shadow-sm mb-4"
                        >
                            {/* Ürün Görseli */}
                            <img
                                src={`https://d2buqeq24qhiv5.cloudfront.net/250x250/pool/${product.pool.real_image}`}
                                alt={product.pool.name}
                                className="w-24 h-24 object-cover rounded"
                            />

                            {/* Ürün Bilgileri */}
                            <div className="flex-1 ml-2">
                                <h2 className="text-lg font-semibold">{product.pool.name}</h2>
                                <p className="text-sm text-gray-500">Referans: {product.pool.stock_code}</p>
                                <p className="text-sm text-gray-500">DOT: {product.dot}</p>
                                <p className="text-sm text-gray-500">Satıcı: {product.firm.caption} | {product.firm.city} - {product.firm.county}</p>

                                {/* Adet Kontrolü ve Tutar */}
                                <div className="flex items-center space-x-4 mt-4">
                                    <p className="text-lg font-semibold">
                                        {formatCurrency(
                                            (parseFloat(product.price) + parseFloat(product.total_vat)) * getItemQuantity({
                                                product_id: product.id
                                            })
                                        )} TL
                                    </p>
                                    <AddToCartButton
                                        productId={product.id}
                                        poolId={product.pool.id}
                                        maxQuantity={product.quantity}
                                        firmId={product.firm_id}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="space-y-4">
                    {services.map((service) => (
                        <div
                            key={service.code}
                            className="flex items-start justify-between border-b pb-4 bg-white rounded-[6px] shadow-sm mb-4"
                        >
                            {/* Ürün Görseli */}
                            <img
                                src={`https://lastsis.s3.amazonaws.com/lastsis/uploads/icons/${service.code}.jpg`}
                                alt={service.name}
                                className="w-24 h-24 object-cover rounded"
                            />

                            {/* Ürün Bilgileri */}
                            <div className="flex-1 ml-2">
                                <h2 className="text-lg font-semibold">{service.name}</h2>
                                <p className="text-sm text-gray-500">Satıcı: {service.firm.caption} | {service.firm.city} - {service.firm.county}</p>

                                {/* Adet Kontrolü ve Tutar */}
                                <div className="flex items-center space-x-4 mt-4">
                                    <p className="text-lg font-semibold">
                                        {formatCurrency(
                                            (parseFloat(service.price) + parseFloat(service.total_vat)) * getItemQuantity({
                                                service_id: service.id
                                            })
                                        )} TL
                                    </p>
                                    <AddToCartServiceButton
                                        serviceId={service.id}
                                        firmId={service.firm_id}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Firma Hizmetleri Bölümü */}
                {products.length > 0 && services.length === 0 && firmServices.length > 0 && (
                    <div className="mt-8">
                        <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                            <h2 className="text-xl font-semibold mb-2">
                                Hizmet eklemeyi unutmayın!
                            </h2>
                            <p className="text-gray-600 text-sm">
                                Bu firmadan alabileceğiniz hizmetlere göz atın
                            </p>
                        </div>
                        
                        <div className="flex flex-wrap pb-20">
                            {firmServices.map((service) => (
                                <ServiceCard 
                                    key={service.id} 
                                    service={service}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* Toplam Tutar ve Ödeme Butonu */}
            <div className="fixed bottom-[60px] left-0 w-full bg-white shadow-md p-4 flex items-center justify-between border-t border-gray-300">
                <h2 className="text-xl font-bold">Toplam: {formatCurrency(totalAmount)} TL</h2>
                <a href={`/checkout`}
                    className="px-6 py-2 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition"
                >
                    Ödeme
                </a>
            </div>
        </div>
    );
};

export default CartPage;