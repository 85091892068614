// src/components/AddToCartButton.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartPlus, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../contexts/CartContext';

interface AddToCartServiceButtonProps {
    serviceId: number;
    firmId: number;
}

const AddToCartServiceButton: React.FC<AddToCartServiceButtonProps> = ({ serviceId, firmId }) => {
    const { addToCart, removeFromCart, getItemQuantity } = useCart();
    const quantity = getItemQuantity({
        service_id: serviceId
    });

    const handleAddToCart = () => {
        addToCart(firmId, {
            service_id: serviceId
        });
    };

    return (
        <div className="flex items-center space-x-2">
            {quantity > 0 ? (
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => removeFromCart({
                            service_id: serviceId
                        })}
                        className="px-2 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition text-[10px]"
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <span className="text-md">{quantity}</span>
                    <button
                        onClick={handleAddToCart}
                        className="px-2 py-1 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-[10px]"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            ) : (
                <button
                    onClick={handleAddToCart}
                    className="px-2 py-1 bg-blue-500 text-white rounded-[6px] hover:bg-blue-600 transition text-[12px]"
                >
                    <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                    Ekle
                </button>
            )}
        </div>
    );
};

export default AddToCartServiceButton;