// src/contexts/CartContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
// @ts-ignore
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/css';

interface Address {
    name?: string;
    taxNumber?: string;
    address: string;
    city: string;
    county: string;
    email: string;
    phone: string;
}

interface CartItem {
    product_id?: number;
    service_id?: number;
    quantity: number;
    firmId: number;
}

interface CartContextType {
    cartItems: CartItem[];
    address?: Address;
    setAddress: (newAddress: Address) => void;
    addToCart: (firmId: number, options: { product_id?: number; service_id?: number }) => void;
    removeFromCart: (options: { product_id?: number; service_id?: number }) => void;
    clearCart: () => void;
    getItemQuantity: (options: { product_id?: number; service_id?: number }) => number;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = (): CartContextType => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

const bounceIn = keyframes`
  0% { transform: scale(0.8); opacity: 0; }
  50% { transform: scale(1.05); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cartItems, setCartItems] = useState<CartItem[]>(() => {
        const savedCart = localStorage.getItem('items');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    const [address, setAddress] = useState<Address | undefined>(undefined);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        localStorage.setItem('items', JSON.stringify(cartItems));
    }, [cartItems]);

    const clearCart = () => {
        setCartItems([]);
        setShowWarning(false);
    };

    const addToCart = (firmId: number, options: { product_id?: number; service_id?: number }) => {
        const differentFirm = cartItems.some((item) => item.firmId !== firmId);
        
        if (differentFirm) {
            setShowWarning(true);
            return;
        }

        setCartItems((prevItems) => {
            const existingItem = prevItems.find((item) => 
                options.service_id 
                    ? item.service_id === options.service_id 
                    : item.product_id === options.product_id
            );
            
            console.log('existingItem', existingItem);
            if (existingItem) {
                return prevItems.map((item) =>
                    ((item.product_id === options.product_id) || (item.service_id === options.service_id))
                        ? { ...item, quantity: item.quantity + 1 } 
                        : item
                );
            }
            
            return [...prevItems, { 
                ...options,
                quantity: 1, 
                firmId,
            }];
        });
    };

    const removeFromCart = (options: { product_id?: number; service_id?: number }) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find((item) => 
                options.service_id 
                    ? item.service_id === options.service_id 
                    : item.product_id === options.product_id
            );
            
            if (existingItem) {
                if (existingItem.quantity === 1) {
                    return prevItems.filter((item) => 
                        options.service_id 
                            ? item.service_id !== options.service_id 
                            : item.product_id !== options.product_id
                    );
                }
                
                return prevItems.map((item) =>
                    ((item.product_id === options.product_id) || (item.service_id === options.service_id))
                        ? { ...item, quantity: item.quantity - 1 } 
                        : item
                );
            }
            return prevItems;
        });
    };

    const getItemQuantity = (options: { product_id?: number; service_id?: number }) => {
        const item = cartItems.find((item) => 
            options.service_id 
                ? item.service_id === options.service_id 
                : item.product_id === options.product_id
        );
        return item ? item.quantity : 0;
    };

    return (
        <CartContext.Provider value={{ cartItems, address, setAddress, addToCart, removeFromCart, clearCart, getItemQuantity }}>
            {children}
            <Modal
                isOpen={showWarning}
                onRequestClose={() => setShowWarning(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: '30px',
                        width: '400px',
                        height: '300px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        animation: `${bounceIn} 0.5s ease`,
                        zIndex: 999999,
                    },
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1999 },
                }}
                ariaHideApp={false}
            >
                <div className="mb-4">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" size="3x" />
                </div>
                <h2 className="text-lg font-bold mb-4">Uyarı</h2>
                <p className="mb-4 text-sm text-gray-700">
                    Sepetinizde başka satıcıya ait bir ürün olduğu için devam edemezsiniz.
                    Lütfen diğer firmaya ait ürünü sepetinizden çıkarın.
                </p>
                <div className="flex">
                    <button
                        onClick={() => setShowWarning(false)}
                        className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition mr-4"
                    >
                        Vazgeç
                    </button>
                    <button
                        onClick={clearCart}
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    >
                        Sepetimi Sıfırla
                    </button>
                </div>
            </Modal>
        </CartContext.Provider>
    );
};