// src/CheckoutPage.tsx
import React, { useState } from 'react';
import AddressForm from '../components/AddressForm';
import VehicleForm from '../components/VehicleForm';
import PaymentForm from '../components/PaymentForm';
import {FormProvider, useForm} from "../contexts/FormContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import AddressCard from "../components/AddressCard";

const fieldTranslations = {
    companyName: 'Şirket Adı',
    taxNumber: 'Vergi Numarası',
    taxOffice: 'Vergi Dairesi',
    name: 'İsim',
    tcNumber: 'TC Kimlik Numarası',
    address: 'Açık Adres',
    city: 'İl',
    district: 'İlçe',
    email: 'Email Adresi',
    phone: 'Telefon Numarası',
};

const CheckoutPage: React.FC = () => {
    const [step, setStep] = useState(1); // Adımları yönetmek için state

    const handlePayment = (paymentData: any) => {

    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <FormProvider>
                <PaymentForm onSave={handlePayment} />
            </FormProvider>
        </div>
    );
};

export default CheckoutPage;