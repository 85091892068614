// src/components/ProductList.tsx
import React from 'react';
import Product from './Product';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useProductFetcher} from '../hooks/useProductFetcher';
import {useFilter} from '../contexts/FilterContext';
import ClipLoader from 'react-spinners/ClipLoader';
import Filter from "./Filter";

const ProductList: React.FC = () => {
    const {products, loading, error, hasMore, loadMore} = useProductFetcher();
    const {filters, applyFilters} = useFilter(); // Filtreleri context'ten alıyoruz

    const handleClearFilters = () => {
        applyFilters({}); // Tüm filtreleri sıfırla
    };

    if (loading && products.length === 0) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader color="#3498db" size={50}/>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    if (!loading && products.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/4076/4076432.png"
                    alt="No Results"
                    className="w-24 h-24 mb-4"
                />
                <p className="text-lg font-bold text-gray-700">
                    Aramanıza ilişkin ürün bulunamadı
                </p>
                {Object.values(filters).some((value) => value) && (
                    <button
                        onClick={handleClearFilters}
                        className="px-4 py-1 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-md transition"
                    >
                        Filtreleri Temizle
                    </button>
                )}
            </div>
        );
    }

    return (
        <div className="p-1">
            <div className="flex mb-4 flex-row justify-between items-center">
                <Filter />
            </div>
            <div className="flex">
                <div className="">
                    {Object.values(filters).some((value) => value) && (
                        <button
                            onClick={handleClearFilters}
                            className="px-4 py-1 bg-red-500 bg-opacity-10 text-red-500 text-[12px] rounded-md transition mb-4"
                        >
                            Filtreleri Temizle
                        </button>
                    )}
                </div>
            </div>

            {/* Ürün Listesi */}
            <InfiniteScroll
                dataLength={products.length}
                next={loadMore}
                hasMore={hasMore}
                className='flex flex-wrap'
                loader={
                    <div className="flex justify-center mt-4">
                        <ClipLoader color="#3498db" size={30}/>
                    </div>
                }
                endMessage={
                    <p className="text-center mt-4 text-gray-500">
                        Tüm ürünler yüklendi.
                    </p>
                }
            >
                {products.map((product) => (
                    <Product key={product.id} product={product}/>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default ProductList;